import React, { useState, useEffect } from "react";
import { useStatePersist } from "use-state-persist";

import fetchJsonp from "fetch-jsonp";

import { Link } from "gatsby";

import "url-search-params-polyfill";

require("es6-promise").polyfill();

let keywordTime = null;

const setVacancySavedLoadingBackup = function setVacancySavedLoadingBackup(
  loading,
  vacancySavedLoading
) {
  if (loading) {
    return true;
  }

  return false;
};

let prevCount = 0;
let currentCount = 0;

const SavedJobsList = ({
  siteConfig,
  vacancySavedLoading = true,
  apiKey = "",
  gdprKey = "",
  group = false,
  CompanyGroupName = "",
  OptInLink = "",
  UnsubscribeLink = "",
  VacancyPageBaseURL = "",
  ReturnToSearchURL = "",
  location = true,
  region = false,
  category = true,
  industry = false,
  jobType = true,
  jobTime = true,
  contentDataData = [],
  setVacancySavedLoading = setVacancySavedLoadingBackup,
  smartfeedCompanyData = {},
}) => {
  const id = 1;
  const [vacancySaved, setVacancySaved] = useState([]);
  const [vacancySavedTitle, setVacancySavedTitle] = useState(
    "Loading saved vacancies..."
  );
  const [isIinitialLoad, setIsIinitialLoad] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);

  const [savedJobs, setSavedJobs] = useStatePersist("@savedJobs_" + apiKey, []);

  const [vacsCount, setVacsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadMoreText, setLoadMoreText] = useState(false);

  let groupOrIdParam = "id";

  if (group) {
    groupOrIdParam = "group";
  }

  function unSaveJob(id) {
    const newSavedJobsArray = savedJobs.filter((e) => e !== id.toString());
    setSavedJobs(newSavedJobsArray);
  }

  function saveJob(id) {
    setSavedJobs((savedJobsOld) => [...savedJobsOld, id]);
  }

  function checkJobHasExpired(JSONDate) {
    if (JSONDate) {
      let expDate = new Date(parseInt(JSONDate.substr(6), 10));
      let now = new Date();

      if (expDate > now) {
        return false;
      }
    }
    return true;
  }

  const fetchVacancies = async (
    state,
    name,
    e,
    loadMore,
    postingTypeSelection
  ) => {
    let postingTypeSelectionValue = "careers";

    // State of filters, Name of searched filter, event, load more boolean
    setLoadMoreText("Loading your saved jobs...");

    if (!loadMore) {
      prevCount = 0;
      currentCount = 0;
    }

    function getAdvertData(jobs, prevCount, currentCount) {
      return Promise.all(
        jobs.slice(prevCount, currentCount).map(async (id) => {
          return fetchJsonp(
            siteConfig.sfAPIEndpoint + "/CareerPage/GetItem?id=" + id,
            {
              timeout: 15000,
            }
          )
            .then(function (response) {
              return response.json();
            })
            .catch(function (error) {
              console.error(error);
              unSaveJob(id);
            });
        })
      );
    }

    try {
      prevCount = currentCount;
      currentCount = currentCount + 12;

      await getAdvertData(savedJobs, prevCount, currentCount).then(function (
        data
      ) {
        data.forEach((job) => {
          if (checkJobHasExpired(job.ExpiryDate)) {
            unSaveJob(job.Id);
            return;
          }
        });

        if (loadMore) {
          setVacancySaved(vacancySaved.concat(data));
        } else {
          setVacancySaved(data);
        }

        if (savedJobs.length === 0) {
          setVacancySavedTitle("We couldn't find any saved jobs");
        } else if (savedJobs.length === 1) {
          setVacancySavedTitle("You have " + savedJobs.length + " saved job");
        } else {
          setVacancySavedTitle("You have " + savedJobs.length + " saved jobs");
        }

        setInitialLoading(false);

        setLoading(false);
        setVacancySavedLoading(false);

        setLoadMoreText("Load More");
        setVacsCount(savedJobs.length);
      });
    } catch (error) {
      setLoading(false);
      setVacancySavedLoading(false);
      console.error(error.message, "error");
    }
  };

  const loadMore = function loadMore() {
    fetchVacancies({}, undefined, undefined, true);
  };

  const getSummary = function getSummary(summary, description) {
    if (summary) {
      return summary;
    } else {
      let alteredDescription = description.replace(/<[^>]*>?/gm, "");
      let tempSummary = alteredDescription.substring(0, 397);
      if (tempSummary.length === 397) {
        tempSummary += "...";
      }
      return tempSummary;
    }
  };

  const calculateSalary = function calculateSalary(
    Currency,
    SalaryFrom,
    SalaryTo,
    SalaryType,
    SalaryPeriod
  ) {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    let NewSalary;
    let NewSalaryFrom;
    let NewSalaryTo;

    let currencyFormatted = Currency;

    if (Currency === "&pound;") {
      currencyFormatted = "£";
    }

    if (SalaryTo === 0) {
      NewSalary = SalaryType;
    } else if (SalaryFrom === SalaryTo) {
      if (SalaryFrom >= 1000) {
        // Remove decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        // Add two decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      NewSalary = NewSalaryFrom + " " + SalaryPeriod;
    } else {
      if (SalaryFrom >= 1000) {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      if (SalaryTo >= 1000) {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed());
      } else {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2));
      }

      NewSalary = NewSalaryFrom + " - " + NewSalaryTo + " " + SalaryPeriod;
    }
    return NewSalary;
  };

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    dateFormatOptions.weekday = "long";
    dateFormatOptions.year = "numeric";
    dateFormatOptions.month = "short";
    dateFormatOptions.day = "numeric";
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  };

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  };

  useEffect(() => {
    fetchVacancies({}, undefined, undefined, false);
  }, [savedJobs]);

  return (
    <div className={vacancySavedLoading ? "vacancies-landing-loading" : ""}>
      {!vacancySavedLoading &&
      smartfeedCompanyData &&
      smartfeedCompanyData.CompanyName ? (
        <>
          <div className="vacancies-landing">
            <div className="vacancies banner page">
              <div className="container">
                <div className="bannerWrapper">
                  <h1>
                    Your saved jobs from {smartfeedCompanyData.CompanyName}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <section className={`main-vacs-wrap`}>
            <div>
              <div className="container">
                <div className="row clearfix">
                  <div className="col-12 column job-vacancies-col">
                    <h3 className="vacancies-landing__header__heading">
                      {vacancySavedTitle}
                    </h3>
                    <div className="vf-careers-site">
                      <div id="VF_vacancies">
                        <div className="row VF_vacancies_main_wrap">
                          <div className="col-12">
                            <div className="main-section">
                              <div className="row search-container-wrap">
                                <div className="inner-content grid-section box-theme-2 joblist-section col-xs-12 col-12">
                                  <div
                                    id="VF-vacancies-wrapper"
                                    className="col-12 VF_vacancies_list"
                                  >
                                    <div className="row">
                                      {loading ? (
                                        "Loading vacancies..."
                                      ) : (
                                        <>
                                          {vacancySaved &&
                                          vacancySaved !== [] &&
                                          vacancySaved !== "[]" &&
                                          !initialLoading ? (
                                            <>
                                              {vacancySaved &&
                                              vacancySaved.length > 0 ? (
                                                vacancySaved.map((job, i) => (
                                                  <div
                                                    className="job-card card"
                                                    id={new Buffer(
                                                      job.Id
                                                    ).toString("base64")}
                                                    key={i}
                                                  >
                                                    <div className="job-card-details">
                                                      <div className="job-card-savejob">
                                                        <div
                                                          title="Save this job for later"
                                                          className="job-card-savejob-trigger"
                                                          role="button"
                                                          onClick={() => {
                                                            if (
                                                              savedJobs.filter(
                                                                (e) =>
                                                                  e ==
                                                                  job.Id.toString()
                                                              ).length > 0
                                                            ) {
                                                              unSaveJob(job.Id);
                                                            } else {
                                                              saveJob(job.Id);
                                                            }
                                                          }}
                                                        >
                                                          {savedJobs.filter(
                                                            (e) =>
                                                              e ==
                                                              job.Id.toString()
                                                          ).length > 0 ? (
                                                            <span className="fas fa-heart"></span>
                                                          ) : (
                                                            <span className="far fa-heart"></span>
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className="job-card-title">
                                                        <Link
                                                          to={
                                                            "/vacancies/vacancy/" +
                                                            new Buffer(
                                                              job.Id
                                                            ).toString(
                                                              "base64"
                                                            ) +
                                                            "/" +
                                                            slugify(
                                                              job.JobTitle
                                                            ) +
                                                            window.location
                                                              .search
                                                          }
                                                        >
                                                          {job.JobTitle}
                                                        </Link>
                                                      </div>
                                                      <div className="company-logo">
                                                        <img
                                                          src={
                                                            job.CompanyLogoPath
                                                          }
                                                          alt={job.Company}
                                                        />
                                                      </div>
                                                      <div className="job-card-location">
                                                        {job.Location}
                                                      </div>
                                                      <div className="job-card-summary">
                                                        {getSummary(
                                                          job.Summary,
                                                          job.Description
                                                        )}
                                                      </div>
                                                      <div className="job-card-benefits">
                                                        Benefits: {job.Benefits}
                                                      </div>
                                                      <div className="job-card-reference">
                                                        {"(Reference: " +
                                                          job.AdvertReference +
                                                          ")"}
                                                      </div>
                                                      <div className="job-card-buttons-wrap">
                                                        <Link
                                                          className="job-card-button btn--secondary"
                                                          to={
                                                            "/vacancies/vacancy/" +
                                                            new Buffer(
                                                              job.Id
                                                            ).toString(
                                                              "base64"
                                                            ) +
                                                            "/" +
                                                            slugify(
                                                              job.JobTitle
                                                            ) +
                                                            window.location
                                                              .search
                                                          }
                                                        >
                                                          More details
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))
                                              ) : (
                                                <div className="job-card card job-card-no-results">
                                                  <div className="job-card-details">
                                                    <div className="no-results">
                                                      We could not find any
                                                      saved jobs for you.
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div className="no-vacancies">
                                              Loading vacancies...
                                            </div>
                                          )}

                                          <div className="load-more-wrap col-12">
                                            {currentCount < vacsCount && (
                                              <a
                                                className="nav-action-sm"
                                                role="button"
                                                onClick={() => loadMore()}
                                              >
                                                {loadMoreText}
                                              </a>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="page-loading-block">
          <div className="loading-panel">
            <div className="loading-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <span className="loading-text">
              Please wait... If content is not shown soon then you may have the
              wrong URL.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavedJobsList;
